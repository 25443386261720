// VARS
$circle-width: 0.09em;
$circle-width-hover: 0.07em;

// colors default
$primary-color: #000000; // czarny
$secondary-color: #D9D9D9; //szary bcg
$bg-color: #ffffff; //srodkowy bezowy 

// colors customized
$primary-color-blue: #30bae7;
$primary-color-green: #15c7a8;
$primary-color-orange: #eb7d4b;
$primary-color-pink: #FF147F;
$primary-color-span: #3c4761;

@mixin rotate($deg) {
    moz-transform: rotate($deg);
    -ms-transform: rotate($deg);
    -webkit-transform: rotate($deg);
    transform: rotate($deg);
}

@mixin box-sizing($val){
    box-sizing: $val;
}

@mixin transition-property($val){
    transition-property: $val;
    -webkit-transition-property: $val;
    -moz-transition-property: $val;
}

@mixin transition-duration($val){
    transition-duration: $val;
    -webkit-transition-duration: $val;
    -moz-transition-duration: $val;
}

@mixin transition-timing-function($val){
    transition-timing-function: $val;
    -webkit-transition-timing-function: $val;
    -moz-transition-timing-function: $val;
}

// CIRCLE
// classes 2 extend
.rect-auto {
    clip: rect(auto, auto, auto, auto);
}

.pie {
    position: absolute;
    border: $circle-width solid $primary-color;
    width: 1 - (2 * $circle-width);
    height: 1 - (2 * $circle-width);
    clip: rect(0em, 0.5em, 1em, 0em);
    border-radius: 50%;
    @include rotate(0deg);
}

.pie-fill {
    @include rotate(180deg);
}

.wrapper {
    // width: 1200px;
    margin: 0 auto;
}


// main
.c100 {
    *,
    *:before,
    *:after {
        @include box-sizing(content-box);
    }

    position: relative;
    font-size: 2.5rem;
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    float: left;
    // margin: 0.1em;
    background-color: $secondary-color;

    // 	// center circle to its parent
    // 	&.center{
    // 		float: none;
    // 		margin: 0 auto;
    // 	}

    // 	&.small{
    // 		font-size: 80px;
    // 	}

    // centered value inside circle
    >span {
        position: absolute;
        width: 100%;
        z-index: 1;
        left: 0;
        top: 0;
        width: 2.5rem;
        line-height: 2.5rem;
        font-size: 0.5rem;
        color: $primary-color-pink;
        font-weight: 600;
        display: block;
        text-align: center;
        white-space: nowrap;
        @include transition-property(all);
        @include transition-duration(0.2s);
        @include transition-timing-function(ease-out);
    }

    // background inside the circle
    &:after {
        position: absolute;
        top: $circle-width;
        left: $circle-width;
        display: block;
        content: " ";
        border-radius: 50%;
        background-color: $bg-color;
        width: 1 - (2 * $circle-width);
        height: 1 - (2 * $circle-width);
        @include transition-property(all);
        @include transition-duration(0.2s);
        @include transition-timing-function(ease-in);

    }

    // the slice (mask)
    .slice {
        position: absolute;
        width: 1em;
        height: 1em;
        clip: rect(0em, 1em, 1em, 0.5em);
    }

    // circle to show the status
    .bar {
        @extend .pie;
    }


    // loop to create all needed elements automatically
    @for $j from 51 through 100 {

        &.p#{$j} .slice {
            @extend .rect-auto;
        }

        &.p#{$j} .bar:after {
            @extend .pie-fill;
        }

        &.p#{$j} .fill {
            @extend .pie;
            @extend .pie-fill;
        }

    }

    // loop to rotate all 100 circles
    @for $j from 1 through 100 {
        &.p#{$j} .bar {
          //  @include rotate((360/100*$j) + deg);
        }
    }



    // hover styles
    &:hover {
        cursor: default;
        >span {
            width: 3.33em;
            line-height: 3.33em;
            font-size: 0.3em;
            color: $primary-color-span;
        }

        &:after {
            top: $circle-width-hover;
            left: $circle-width-hover;
            width: 1 - (2 * $circle-width-hover);
            height: 1 - (2 * $circle-width-hover);
        }

    }

    // blue
    &.blue {

        .bar,
        .fill {
            border-color: $primary-color-blue !important;
        }

        &:hover {
            >span {
                color: $primary-color-span;
            }
        }

    }


    // pink skin
    &.pink {

        .bar,
        .fill {
            border-color: $primary-color-pink !important;
        }

        &:hover {
            >span {
                color: $primary-color-span;
            }
        }

    }


    // green skin
    &.green {

        .bar,
        .fill {
            border-color: $primary-color-green !important;
        }

        &:hover {
            >span {
                color: $primary-color-span;
            }
        }

    }


    // orange skin
    &.orange {

        .bar,
        .fill {
            border-color: $primary-color-orange !important;
        }

        &:hover {
            >span {
                color: $primary-color-span;
            }
        }

    }

}