$border_right_color: #D5A529;
$background: #1D1500;
$header_bg: #1D1500;
$menu_active_text: #ffffff;
$menu_inactive_text: #686868;
$mm_collapse_bg: transparent;

.ai-icon {
    display: flex !important;
    align-items: center !important;
    border-radius: 0px;
}

.icon-active {
    display: none;
}

.mm-parent-active,
.mm-active {
    .icon-active {
        display: inline-block !important;
    }

    .icon-inactive {
        display: none !important;
    }
}



.parentMenu {
    display: flex;
    align-items: center;
}

.deznav {
    .metismenu {
        padding-bottom: 100px;
        // overflow-y: auto;

        li {
            a {
                color: $menu_inactive_text !important;

                &::before {
                    width: 0px !important;
                }
            }
            .nav-text.parent{
                color: $menu_active_text !important;
            }

            &.mm-active {
                a {
                    background: transparent !important;
                    box-shadow: none !important;

                    .nav-text {
                        color: $menu_inactive_text !important;
                    }

                    .nav-text.parent{
                        color: $menu_active_text !important;
                    }
                }
            }

            &.mm-parent-active {
                a {
                    color: $menu_active_text !important;
                    background: var(--primary-color-1) !important;
                    box-shadow: none !important;
                    border-radius: 9px !important;
                }
            }

            &.menu-active {
                a {
                    color: $menu_active_text !important;
                    // background: var(--primary-color) !important;
                    box-shadow: none !important;
                }

                &:hover {
                    a {
                        color: $menu_active_text !important;
                        box-shadow: none !important;
                    }

                    .icon-active {
                        display: inline-block;
                    }

                    .icon-inactive {
                        display: none;
                    }

                }
            }

            &.menu-inactive {
                a {
                    color: $menu_inactive_text !important;
                    // background: var(--primary-color) !important;
                    box-shadow: none !important;
                }

                &:hover {
                    a {
                        color: $menu_active_text !important;
                        box-shadow: none !important;
                    }

                    .icon-active {
                        display: inline-block;
                    }

                    .icon-inactive {
                        display: none;
                    }

                }
            }

            .nav-text.parent{
                &:hover{
                    color: $menu_active_text !important;
                }
            }
        }
    }
}

[data-sidebar-position="fixed"][data-layout="vertical"] {

    .deznav{
        background-color: none;
        background: $background;
    }
    .nav-header {
        background-color: none;
        background: $header_bg;
    }
}

.small-title-text {
    color: var(--menu-small-title);
}

[data-sidebar-style="full"][data-layout="vertical"] {
    .small-title-text {
        padding: .5rem 2.1875rem;
    }
}


@media only screen and (min-width: 48rem) {
    [data-sidebar-style="mini"] {
        .deznav {
            .ai-icon {
                .d-flex {
                    justify-content: center !important;
                }
            }
        }
    }
}

[data-sidebar-style="full"] {
    .menu-toggle {
        .ai-icon {
            .d-flex {
                justify-content: center !important;
            }
        }
    }
}

@media screen and (max-width: 820px) {
    [data-sidebar-position=fixed][data-layout=vertical] .menu-toggle .deznav {
        z-index: 99 !important;
        min-height: 100vh !important;
        top: 0 !important;
    }
}


@media only screen and (min-width: 48rem) {
    [data-sidebar-style="mini"] .deznav .metismenu li>ul {
        box-shadow: none !important;
        border-radius: 10px !important;
        background: var(--primary-color) !important;
    }
}

[data-sidebar-position="fixed"][data-layout="vertical"] .menu-toggle .deznav {
    z-index: 99;
}

[data-sidebar-style=full][data-layout=vertical] .menu-toggle .deznav .metismenu>li:hover>a{
    background: transparent !important;
}

.deznav{
    border-right: 8px solid $border_right_color;
}

.mobileLogo {
    width: 60%;
    display: none;

    img {
        width: 100%;
    }

    @media screen and (min-width: 481px) and (max-width: 766px) {
        width: 50%;
    }

    @media screen and (max-width: 820px) {
        display: block;
    }
}

.mm-collapse{
    background-color: $mm_collapse_bg !important;
    background: $mm_collapse_bg  !important;
    padding-top: 0px;
    padding-bottom: 0px;
}
.deznav .metismenu ul a {
    padding-left: 3rem !important; 
}

.deznav .metismenu ul a:hover {
    padding-left: 3.5rem !important; 
}