.authHeader {
    position: fixed;
    top: 0;
    z-index: 99;
    &.header-white{
        background-color: #fff;
        // box-shadow: 0px 4px 25px -3px rgba(0,0,0,0.2);
        // -webkit-box-shadow: 0px 4px 25px -3px rgba(0,0,0,0.2);
        // -moz-box-shadow: 0px 4px 25px -3px rgba(0,0,0,0.2);
    }
    &.header-transparent{
        background-color: transparent;
    }

    .logoImg {
        width: 20%;
        @media screen and (max-width: 500px) {
            width: 80%;
        }
        @media screen and (max-width: 600px) {
            width: 80%;
        }
        @media screen and (min-width: 601px) and (max-width: 767px) {
            width: 60%;
        }
        @media screen and (min-width: 768px) and (max-width: 991px) {
            width: 40%;
        }
    }

    .lang-dropdown {
        // background:  var(--primary-color);
        // color: #ffffff;
        // border-radius: 46px;
    }

    .lang-dropdown-menu-lg {
        right: 0%;
        border-radius: 4px;
        margin-top: 8px;
        width: auto;
        padding: 10px;
        // box-shadow: 0 0 5px -1px rgba(0, 0, 0, 0.3);
        background: #fff;
        transform-origin: top left;
        z-index: 999;
        position: absolute;
        top: 100%;
        // overflow: scroll;
        display: none;

        @media screen and (max-width: 767px) {
            width: 100%;
        }
    }

    .lang-dropdown-menu-lg span {
        padding: 10px;
        width: 100%;
        box-sizing: border-box;
        text-align: center;
        cursor: pointer;
        transition: background 0.3s ease;
    }

    .lang-menu-container {
        height: auto;
        overflow-y: auto;
        flex-direction: column;
        display: flex;
        .lang-text{
            transition: all .5s ease-in;
            color: #000;
        }
        @media screen and (max-width: 767px) {
            height: 50%;
        }
    }

    .lang-dropdown-menu-lg span:hover {
        background: var(--primary-color);
        border-radius: 5px;
        color: #fff;
    }

    #openDropdown-lg:checked+.lang-dropdown-menu-lg {
        display: flex;
        animation: openDropDown 0.4s ease 0s .4 forwards;
    }

    @keyframes openDropDown {
        from {
            transform: rotateX(50deg);
        }

        to {
            transform: rotateX(0deg);
        }
    }
}