$qr_container_bg: transparent;
$referral_link_box_bg: #271C00;
$rankDetailsBg: linear-gradient(167deg, #F0F9FF 6.86%, #D3EEFB 60.33%, #B6DCF0 91.7%);
$progress_bar_color: #FFDB7E;

.team{
    .rank-icon{
        max-width: 70px;
    }
    .profile-image{
        border-color: #ffffff;
        border-width: 2px;
        border-radius: 3.125em;
        overflow: hidden;
        width: 6em;
        aspect-ratio: 1;
    }
    .qr-container{
        background: $qr_container_bg;
        border-radius: 1.25em;
    }
    .referral-link-box{
        background: $referral_link_box_bg;
        border-radius: 3.125em;
    }
    .rankDetailsBg {
        background: $rankDetailsBg;
        border-radius: 1.875em;
    }
}
.team-table{
    border-collapse: separate;
    border-spacing: 0px 10px;
    .user-img{
        border-radius: 50%;
        width: 3em;
        height: 3em;
        object-fit: cover;
    }
    thead {
        tr {
            th {
                border-top: 1px solid #EAECF0 !important;
                border-bottom: 1px solid #EAECF0 !important;

                &:first-child {
                    border-left: 1px solid #EAECF0;
                    border-top-left-radius: 6px;
                    border-bottom-left-radius: 6px;
                }

                &:last-child {
                    border-right: 1px solid #EAECF0;
                    border-top-right-radius: 6px;
                    border-bottom-right-radius: 6px;
                }
            }
        }
    }

    tbody {
        tr {
            td {
                border-top: 1px solid #EAECF0;
                border-bottom: 1px solid #EAECF0;

                &:first-child {
                    border-left: 1px solid #EAECF0;
                    border-top-left-radius: 6px;
                    border-bottom-left-radius: 6px;
                }

                &:last-child {
                    border-right: 1px solid #EAECF0;
                    border-top-right-radius: 6px;
                    border-bottom-right-radius: 6px;
                }
            }
        }
    }
    .flag-container{
        border-radius: 50%;
        background: #000;
        height: 3em;
        width: 3em;
        overflow: hidden;
        border: 2px solid #ffffff;
        img{
            object-fit: cover;
        }
    }
    .status{
        &.approved{
            color: #26C63F;
        }
        &.review{
            color: #DAA621;
        }
        &.rejected{
            color: #C10808;
        }
        &.pending{
            color: #6D6D6D;
        }
    }    
    
}

.progress {
    background: #ffffff;
    background-color: #ffffff !important;
    justify-content: flex-start;
    border-radius: 100px;
    align-items: center;
    position: relative;
    display: flex;
    height: .5em;
    width: 100%;
    
    // width: 500px;
}

.progress-value {
    width: 0%;
    animation: load 3s normal forwards;
    box-shadow: 0 10px 40px -10px $progress_bar_color;
    border-radius: 100px;
    // background: #FF147F;
    background: $progress_bar_color;
    background-size: 200% 200%;
    height: .5em;
    transition-duration: 1s;
    transition-property: width;
    transition-timing-function: linear;
    animation: gradient 1s ease infinite;
}

@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }

    50% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0% 50%;
    }
}

.prevArrow{
    left: 0;
    top: 50%;
}

.nextArrow{
    right: 0;
    top: 50%;
}