.authincation{
    background: transparent;
}

.login{
    position: relative;
    background-image: url('../images/background/authBg.png') !important;
    background-color: transparent !important;
    background-repeat: no-repeat;
    background-size: cover;
}
.register{
    position: relative;
    background-color: #ffffff;
    background-repeat: no-repeat;
    .authincation{
        background: none;
        .login-aside{
            background-color: transparent;
            &::after{
                display: none;
            }
            min-width: 40%;
        }
    }
}

.login,
.register {
    .auth-btn {
        cursor: pointer;
        position: relative;
        background: #FFFFFF;
        border-radius: 10px;
        -webkit-transition: all 0.4s;
        -moz-transition: all 0.4s;
        transition: all 0.4s;
        color: #10616F;

        &:hover{
            background: var(--primary-color-1);
            color: #ffffff;
        }
    }
}

.forgotpassword{
    .content{
        margin-top: 10em;  
    }
}

.termsmodalBody{
    height: 70vh;
    overflow-y: auto;
}