$border_right_color: #D5A529;
$header_bg: linear-gradient(167deg, #FFFCF7 6.86%, #FFF9E9 60.33%, #FFEFC5 91.7%);
$profile_bar_bg: #D5A529;
$profile_bar_bg_hover: rgba(213, 165, 41,0.8);
$logout_bar_bg: #CB1D23;
$logout_bar_bg_hover: rgba(203,29,35,0.8);

.header-logo {
    width: 70%;
}

.header-logo-text {
    width: 50%;
}

.header-logo3 {
    width: 60%;
}

[data-sidebar-style="full"] {
    .menu-toggle{
        .nav-header .nav-control .hamburger .line {
            background-color: var(--primary-color) !important;
        }
    }
}

.header-wallet-dropdown-container {
    .wallet-dropdown-menu-lg {
        right: 0;
        display: none;
        border-radius: 0.625em;
        margin-top: 8px;
        // width: auto;
        min-width: 10em;
        padding: 10px;
        box-shadow: 0 0 5px -1px rgba(0, 0, 0, 0.3);
        background: #fafafa;
        transform-origin: top left;
        z-index: 9999;
        position: absolute;
        top: 100%;
        overflow: auto;

    }

    .wallet-dropdown-menu-lg span {
        padding: 10px;
        width: 100%;
        box-sizing: border-box;
        text-align: center;
        cursor: pointer;
        transition: background 0.3s ease;

        @media screen and (max-width: 767px) {
            padding: 1em;
        }

    }

    #walletOpenDropdown-lg:checked+.wallet-dropdown-menu-lg {
        display: flex;
        animation: openDropDown 0.4s ease 0s 1 forwards;
    }

    .wallet-menu-item {
        cursor: pointer;
        color: #111111;
        transition: all .5s;

        &:hover {
            background: var(--primary-color-1);
            border-radius: 5px;
            color: #ffffff;

            svg path {
                fill: #ffffff;
            }

            .hand {
                stroke: #ffffff;
                fill: none !important;
            }

            #mask0_317_1367 {
                fill: none;
            }

            .transaction {
                stroke: #ffffff;
                fill: none;
            }
        }
    }

    @keyframes openDropDown {
        from {
            transform: rotateX(50deg);
        }

        to {
            transform: rotateX(0deg);
        }
    }
}

.header-scan-dropdown-container {
    .scan-dropdown-menu-lg {
        right: 0;
        display: none;
        border-radius: 0.625em;
        margin-top: 8px;
        width: auto;
        padding: 10px;
        box-shadow: 0 0 5px -1px rgba(0, 0, 0, 0.3);
        background: #fafafa;
        transform-origin: top left;
        z-index: 9999;
        position: absolute;
        top: 100%;
        overflow: auto;

        @media screen and (max-width: 767px) {
            width: auto;
        }
    }

    #scanOpenDropdown-lg:checked+.scan-dropdown-menu-lg {
        display: flex;
        animation: openDropDown 0.4s ease 0s 1 forwards;
    }

    .referral-qr-container {
        background: #ffffff;
        border-radius: 5px;
    }

    @keyframes openDropDown {
        from {
            transform: rotateX(50deg);
        }

        to {
            transform: rotateX(0deg);
        }
    }
}

.header-noti-dropdown-container {
    .noti-dropdown-menu-lg {
        right: 0;
        display: none;
        border-radius: 0.625em;
        margin-top: 8px;
        width: auto;
        min-width: 20em;
        padding: 10px;
        box-shadow: 0 0 5px -1px rgba(0, 0, 0, 0.3);
        background: #fafafa;
        transform-origin: top left;
        z-index: 9999;
        position: absolute;
        top: 100%;
        overflow: auto;

        @media screen and (max-width: 767px) {
            width: auto;
        }
    }

    .noti-menu-container {
        height: auto;
        // overflow-y: auto;
        flex-direction: column;
        display: flex;
        min-width: 10em;

        @media screen and (max-width: 767px) {
            height: 50%;
        }
    }

    #notiOpenDropdown-lg:checked+.noti-dropdown-menu-lg {
        display: flex;
        animation: openDropDown 0.4s ease 0s 1 forwards;
    }

    @keyframes openDropDown {
        from {
            transform: rotateX(50deg);
        }

        to {
            transform: rotateX(0deg);
        }
    }
}

.header-lang-dropdown-container {
    .lang-dropdown-menu-lg {
        right: 0;
        display: none;
        border-radius: 0.625em;
        margin-top: 8px;
        // width: auto;
        min-width: 10em;
        padding: 10px;
        box-shadow: 0 0 5px -1px rgba(0, 0, 0, 0.3);
        background: #fafafa;
        transform-origin: top left;
        z-index: 9999;
        position: absolute;
        top: 100%;
        overflow: auto;
    }

    .lang-dropdown-menu-lg span {
        padding: 10px;
        width: 100%;
        box-sizing: border-box;
        text-align: center;
        cursor: pointer;
        transition: background 0.3s ease;

        @media screen and (max-width: 767px) {
            padding: 1em;
        }

    }

    .lang-menu-container {
        height: auto;
        overflow-y: auto;
        flex-direction: column;
        display: flex;

        .lang-text {
            transition: all .5s ease-in;
            color: var(--text-grey-1);
        }

        @media screen and (max-width: 767px) {
            height: 50%;
        }
    }

    .lang-dropdown-menu-lg span:hover {
        background: var(--primary-color-1);
        border-radius: 5px;
        color: #000;
    }

    #langOpenDropdown-lg:checked+.lang-dropdown-menu-lg {
        display: flex;
        animation: openDropDown 0.4s ease 0s 1 forwards;
    }

    @keyframes openDropDown {
        from {
            transform: rotateX(50deg);
        }

        to {
            transform: rotateX(0deg);
        }
    }
}


.header-emptyImg-container {
    aspect-ratio: 1;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    img{
        border-radius: 50%;
    }
}

.header-profile-dropdown-container {
    .profile-dropdown-menu-lg {
        right: 0;
        display: none;
        border-radius: 1.25em;
        margin-top: 8px;
        width: auto;
        // background: #ffffff;
        background: $header_bg;
        backdrop-filter: blur(10px);
        transform-origin: top left;
        z-index: 999;
        position: absolute;
        top: 100%;
        // overflow: auto;
        width: 20em;

        @media screen and (max-width: 767px) {
            width: 100%;
        }
    }

    .profileImgSize {
        width: 80px;
    }

    .profile-dropdown-menu-lg .item {
        padding: 10px;
        width: 100%;
        box-sizing: border-box;
        text-align: center;
        cursor: pointer;
        transition: background 0.3s ease;
    }

    .profile-menu-container {
        height: auto;
        overflow-y: auto;
        flex-direction: column;
        display: flex;

        @media screen and (max-width: 767px) {
            height: 50%;
        }
    }

    .middle-content {
        border-bottom: 1px solid #EAECF0;
    }

    .profile-menu-item {
        cursor: pointer;
        color: #fff;
        background: $profile_bar_bg;
        border-radius: 1.25em;

        &:hover {
            background: $profile_bar_bg_hover;
            border-radius: 5px;
            color: #ffffff;

            svg path {
                fill: #ffffff;
            }
        }
    }

    .profile-menu-item2 {
        cursor: pointer;
        color: #fff;
        background: $logout_bar_bg;
        border-radius: 1.25em;

        &:hover {
            background: $logout_bar_bg_hover;
            border-radius: 5px;
            color: #ffffff;

            svg path {
                fill: #ffffff;
            }
        }
    }

    .light-green-container{
        background-color: #ffffff;
        border-radius: 1em;
    }

    #openDropdownProfile-lg:checked+.profile-dropdown-menu-lg {
        display: flex;
        animation: openDropDown 0.4s ease 0s 1 forwards;

        @media screen and (max-width: 767px) {
            width: 65vw;
        }

        @media screen and (max-width: 300px) {
            width: 80vw;
        }
    }

    @keyframes openDropDown {
        from {
            transform: rotateX(50deg);
        }

        to {
            transform: rotateX(0deg);
        }
    }
}


.page-header {
    font-size: 1.25em;

    @media screen and (max-width: 300px) {
        display: none;
    }

    @media screen and (max-width: 480px) {
        font-size: 0.875em;
    }

    @media screen and (min-width: 481px) and (max-width: 768px) {
        font-size: 1em;
    }
}

.header {
    border-bottom: 2px solid $border_right_color;
}

.hamburger {
    .line {
        background: #fff !important;
    }
}

@media screen and (max-width: 767px) {

    [data-sidebar-position=fixed][data-layout=vertical] .deznav,
    [data-sidebar-position=fixed][data-layout=vertical] .nav-header {
        border-right: none !important;
        width: 0;

        .hamburger {
            .line {
                background: var(--primary-color) !important;
            }
        }
    }

    .header {
        .header-content {
            padding-left: 0px !important;
        }

        .header-icon {
            width: 1em;
        }

        .header-profile-icon {
            width: 1.5em;
        }
    }

    [data-sidebar-position="fixed"][data-layout="vertical"] .menu-toggle .deznav {
        width: 70%;
    }
}

.noti-number {
    position: absolute;
    right: -.3em;
    top: -.3em;
    border-radius: 50%;
    border: 1px solid var(--primary-color-1);
    background-color: var(--primary-color-1);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1.5em;
    height: 1.5em;
    color: #ffffff;

    @media screen and (max-width: 480px) {
        width: .5em;
        height: .5em;

        span {
            display: none;
        }
    }
}

.unreadDot {
    width: .5em;
    height: .5em;
    border-radius: 50%;
    background: red;
}

.readDot {
    width: .5em;
    height: .5em;
    border-radius: 50%;
    background: transparent;
}

.nav-control{
    top: 50% !important;
}

.nav-header{
    border-right: 8px solid $border_right_color;
}