$odd_color: #EEF9FF;
$even_color: #F8FCFF;

.settings {
    .carousel .control-dots {
        bottom: 0%;
    }

    .carousel .control-dots .dot {
        width: 25px;
        border-radius: 50px;
        background: #005CC6;
    }

    .carousel .control-dots .dot.selected {
        width: 50px;
        background: #4EABEB;
        border-radius: 60px;
    }

    .carousel .slider-wrapper {
        padding-bottom: 40px;
    }

    .carousel .slider-wrapper img {
        border-radius: 30px;
    }

    .announcementScrollSection {
        height: 55vh;
        overflow-y: auto;
    }

    .announcementPopupLine {
        border: 2px solid #cccccc;
    }
}

.statusActive {
    width: 10px;
    height: 10px;
    background: #00D655;
    border-radius: 20px;
}

.statusPending {
    width: 10px;
    height: 10px;
    background: #FF7A00;
    border-radius: 20px;
}

.statusCompleted {
    width: 10px;
    height: 10px;
    background: #0097FF;
    border-radius: 20px;
}

.statusFailed {
    width: 10px;
    height: 10px;
    background: #DF000C;
    border-radius: 20px;
}

.uploadedImg {
    width: 100%;
}

.rankBadgeSize {
    width: 40%;
}

.react-datepicker__input-container {
    input {
        border: none !important;
        padding: 8px 0;
    }
}

.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before {
    display: none;
}

.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after {
    display: none;
}

.transferCompleteBtn {
    background: #D9F2FC;
    border-radius: 107px;
    border: none;

    span {
        color: #102E94;
    }
}

.transfer-btn {
    width: 40px;

    @media (max-width: 677px) {
        width: 40px;
    }

    @media (max-width: 300px) {
        width: 30px;
    }
}
.doc-preview {
    width: auto;
    height: 10em;
    object-fit: contain;
}