$rankDetailsBg: linear-gradient(167deg, #F0F9FF 6.86%, #D3EEFB 60.33%, #B6DCF0 91.7%);
$read_more_text: #D5A529;

.dashboard{
    .announcementScrollSection {
        overflow-y: auto;
        max-height: 20em;
    }
    .rankDetailsBg {
        background: $rankDetailsBg;
        border-radius: 1.875em;
    }

    .read-more-text {
        color: $read_more_text;
        text-decoration: underline;
    }

    .mt5tab{
        border-bottom: 2px solid #EEF9FF;
        cursor: pointer;
        .active{
            color: #ffffff;
        }
        .inactive{
            color: #686868;
        }
    }
    
    .activeTab{
        background-color: #15389C;
        border-radius: 25px;
    }
}

