$color1: #FFDB7E;
$color2: #E0B830;

#loading2 {
    /** end preloader 1 */
    /** preloader 2 */
    .preloader-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100vh;
        position: relative;
        background: url(../images/background/authBg.png);
    }

    .preloader-logo {
        width: 20%;
    }

    .preloader {
        width: 50px;
        height: 2px;
        background-color: rgb(240, 240, 240);
        position: relative;
    }

    .preloader::before {
        content: "";
        position: absolute;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: #999;
        bottom: 20px;
        left: -10px;
        animation: bounce 2s linear infinite;
    }

    .preloader:after {
        content: "";
        position: absolute;
        top: 0;
        left: -2px;
        height: 2px;
        background-color: red;
        animation: color 2s linear infinite;
    }

    @keyframes bounce {
        0% {
            bottom: 20px;
            left: -15px;
            width: 10px;
            opacity: 0;
            background: $color1;
        }

        20% {
            bottom: 0px;
            left: 0;
            width: 10px;
            opacity: 1;
            background: $color2;
        }

        22% {
            bottom: 0px;
            left: 0;
            width: 12px;
            height: 8px;
        }

        27% {
            bottom: 0px;
            left: 2px;
            width: 10px;
            height: 10px;
        }

        50% {
            bottom: 20px;
            left: 17px;
            width: 10px;
            height: 10px;
            background: $color1;
        }

        55% {
            bottom: 20px;
            left: 22px;
            width: 10px;
            height: 10px;
        }

        75% {
            bottom: 0px;
            left: 35px;
            width: 10px;
            height: 10px;
            background: $color2;
        }

        80% {
            bottom: 0px;
            left: 35px;
            width: 12px;
            height: 8px;
        }

        82% {
            bottom: 0px;
            left: 35px;
            width: 10px;
            height: 10px;
            opacity: 1;
        }

        100% {
            bottom: 20px;
            left: 50px;
            width: 10px;
            height: 8px;
            opacity: 0;
        }
    }

    @keyframes color {
        0% {
            width: 0;
            background: $color1;
        }

        20% {
            width: 10px;
            background: $color2;
        }

        50% {
            width: 25px;
            background: $color1;
        }

        75% {
            width: 45px;
            background: $color2;
        }

        100% {
            width: 52px;
            background: $color1;
        }
    }

    /** end preloader 2 */
}