$linecolor: #D5A529;

/* CSS Code */
.border-tree-column {
    border-radius: 10px;
    border: 1px solid #ffffff;
    background: #FFF;
    position: relative;
}

.child-node {
    .border-tree-column::before {
        content: "";
        position: absolute;
        top: 50%;
        bottom: 0;
        left: 0%;
        width: 26px;
        height: 1px;
        background-color: $linecolor;
        transform: translate(-100%, -50%)
    }
}

#tree {

    ol,
    ul {
        list-style: none;
    }

    input {
        // display: none;
    }


    .container {
        z-index: 1;
        margin-bottom: 4em;
    }

    .pure-tree {
        text-align: left;
        display: block;
        white-space: nowrap;
        /* Prevent wrapping of tree nodes */
    }

    .pure-tree.main-tree {
        // width: 100%;
        display: inline-block;
    }

    .pure-tree:not(.main-tree) {
        padding-left: 2em;
        white-space: nowrap;
        height: auto;

    }


    .pure-tree:not(.main-tree) li {
        display: block;
    }

    .pure-tree .single-view {
        position: relative;
        // padding-left: 15px;
        border-radius: 10px;
        background: var(--ffffff, #FFF);
        width: 100%;
        min-width: 200px;

        @media screen and (min-width: 992px) {
            min-width: 70vw;
        }

        @media screen and (max-width: 667px) {
            // min-width: 300%;
        }
    }

    .pure-tree input[type=checkbox]+.single-view:before {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: 2px;
        background-color: #A6E4FF;
        transform: translateX(-50%);
    }



    .pure-tree input[type=checkbox] {
        .single-view::before {
            content: "";
            position: absolute;
            top: 47px;
            left: -19px;
            /* Adjust the left position to align with the minus icon */
            height: 1px;
            /* Adjust the height of the line */
            border-top: 2px solid $linecolor;
            width: 100px;
            /* Extend the line to cover the .single-view width */
            z-index: -1;
            transform: translateX(-50%);

            @media screen and (max-width: 767px) {
                top: 93px;
                left: -16px;

            }

            @media screen and (min-width: 768px) and (max-width:991px) {
                top: 79px;
                left: -16px;

            }
        }
    }




    .pure-tree input[type=checkbox]+.single-view:after {
        width: 26px;
        height: 26px;
        line-height: 1em;
        display: inline-block;
        content: "";
        background: url('../images/icon/plusIcon.svg');
        // padding-right: 1.75em;
        background-repeat: no-repeat;
        position: absolute;
        top: 50%;
        left: -1%;
        transform: translateY(-50%);

        @media screen and (max-width:667px) {
            // top: 50%;
            // left: -4%;
        }

        // @media (orientation: landscape) and (min-width:668px) and (max-width:767px) {
        //   top: 50%;
        //   left: -3%;
        // }
        @media screen and (min-width:668px) and (max-width:767px) {
            top: 50%;
            left: -1.5%;
        }

        @media screen and (min-width:768px) and (max-width:991px) {
            top: 50%;
            left: -1%;
            width: 3em;
        }
    }

    .pure-tree input[type=checkbox]:checked+.single-view:after {
        width: 26px;
        height: 26px;
        line-height: 1em;
        display: inline-block;
        content: "";
        background: url('../images/icon/minusIcon.svg');
        padding-right: 1.75em;
        background-repeat: no-repeat;
        position: absolute;
        // top: 37%;
        left: -1%;
        z-index: 1;

        @media screen and (max-width:667px) {
            top: 50%;
            left: -4%;
        }

        @media screen and (min-width:668px) and (max-width:767px) {
            top: 50%;
            left: -1.5%;
        }

        // @media (orientation: landscape) and (min-width:768px) and (max-width:1000px) {
        //   top: 50%;
        //   left: -2%;
        // }
        @media screen and (min-width:768px) and (max-width:991px) {
            top: 50%;
            left: -2%;
        }
    }

    .pure-tree .single-view:hover {
        color: #434a58;
    }

    .pure-tree .pure-tree_link a {
        padding: 1.500em 1.125em 0.750em 0;
        display: block;
        border-radius: .2em;
        color: #717780;
        text-decoration: none;
    }

    .pure-tree .pure-tree_link a:hover {
        color: #434a58;
    }

    .pure-tree.nested {
        padding-left: 1.7em;
    }

    .pure-tree [type="checkbox"] {
        display: none;
    }

    .pure-tree [type="checkbox"]:checked+.single-view {
        color: #434a58;
    }

    .pure-tree [type="checkbox"]:checked~ul>li {
        height: auto;
    }

    /* Add the line-node class */
    .line-node-parent {
        position: absolute;
        top: 55%;
        left: -2px;
        /* Adjust as needed */

        bottom: 0;
        width: 2px;
        /* Adjust as needed */
        background-color: $linecolor;
        /* Adjust color as needed */
        transform: translateX(-50%);

        @media screen and (max-width:767px) {
            top: 49%;
            left: 0px;

        }

        @media screen and (min-width:767px) and (max-width:991px) {
            left: -1px;

        }

        @media screen and (min-width:1000px) and (max-width:1500px) {
            top: 59%;

        }

    }

    .line-node-child {
        position: absolute;
        top: 65%;
        left: 0px;
        /* Adjust as needed */
        min-height: 100%;
        bottom: 0;
        width: 2px;
        /* Adjust as needed */
        background-color: $linecolor;
        /* Adjust color as needed */
        transform: translateX(-50%);

        @media screen and (max-width: 767px) {
            top: 52%;
        }

        @media screen and (min-width: 1000px) and (max-width: 1500px) {
            top: 70%;
        }
    }


    /* Show connected child node to parent node when checked */
    // .pure-tree input[type="checkbox"]:checked ~ ul > li > .single-view {
    //   border-top: 2px solid #A6E4FF;
    // }

    /* Show line-node-child for checked checkboxes */
    .pure-tree input[type="checkbox"]:checked+.single-view .line-node-child {
        display: block;
    }

    /* Hide line-node-child for unchecked checkboxes */
    .pure-tree input[type="checkbox"]+.single-view .line-node-child {
        display: none;
    }



    /* Hide border top for first lineI apologize for the incomplete response. Here's the remaining part of the code:

  ```css
    /* Hide border top for first line-node-child */
    .pure-tree>li:first-child>.line-node-child {
        border-top: none;
    }



    /* Create border top line connecting .line-node-child and the minus icon */
    .pure-tree input[type="checkbox"]:checked+.single-view .line-node-child:before {
        display: none;
    }



    .pure-tree li:not(:has(> input[type="checkbox"]))>.single-view .line-node-child {
        display: none;
    }

    .pure-tree li:not(:has(input[type="checkbox"])) .single-view::before {
        content: "";
        position: absolute;
        top: 46px;
        /* Adjust the distance from the line to the minus icon */
        left: -14px;
        /* Adjust the left position to align with the minus icon */
        height: 1px;
        /* Adjust the height of the line */
        // border-top: 2px solid #A6E4FF;
        width: 29px;
        /* Extend the line to cover the .single-view width */

        @media screen and (max-width:667px) {
            content: "";
            position: absolute;
            top: 46%;
            /* Adjust the distance from the line to the minus icon */
            left: -13px;
            /* Adjust the left position to align with the minus icon */
            height: 1px;
            /* Adjust the height of the line */
            // border-top: 2px solid #A6E4FF;
            width: 23px;
            /* Extend the line to cover the .single-view width */
        }

        @media screen and (min-width:668px) and (max-width:767px) {
            content: "";
            position: absolute;
            top: 42%;
            /* Adjust the distance from the line to the minus icon */
            left: -13px;
            /* Adjust the left position to align with the minus icon */
            height: 1px;
            /* Adjust the height of the line */
            // border-top: 2px solid #A6E4FF;
            width: 23px;
            /* Extend the line to cover the .single-view width */
        }

        @media screen and (min-width:768px) and (max-width:991px) {
            content: "";
            position: absolute;
            top: 80px;
            /* Adjust the distance from the line to the minus icon */
            left: -14px;
            /* Adjust the left position to align with the minus icon */
            height: 1px;
            /* Adjust the height of the line */
            // border-top: 2px solid #A6E4FF;
            width: 25px;
            /* Extend the line to cover the .single-view width */
        }


        @media screen and (min-width:1000px) and (max-width:1500px) {
            content: "";
            position: absolute;
            top: 43px;
            /* Adjust the distance from the line to the minus icon */
            left: -14px;
            /* Adjust the left position to align with the minus icon */
            height: 1px;
            /* Adjust the height of the line */
            // border-top: 2px solid #A6E4FF;
            width: 25px;
            /* Extend the line to cover the .single-view width */
        }
    }




    .pure-tree>li:not(:has(input[type="checkbox"]:checked))>.single-view .line-node-child:before {
        content: "";
        position: absolute;
        top: -12px;
        left: -18px;
        /* Adjust the left position to align with the minus icon */
        height: 12px;
        /* Adjust the height of the line */
        border-top: 2px solid $linecolor;
        width: 20px;
        /* Extend the line to cover the .single-view width */
        z-index: -1;
        transform: translateX(-50%);

        @media screen and (max-width: 767px) {
            top: -8%;
            left: -20px;
            width: 11px;
        }

        @media screen and (min-width: 1000px) and (max-width: 1500px) {
            content: "";
            position: absolute;
            left: -15px;
            height: 12px;
            border-top: 2px solid #A6E4FF;
            width: 18px;
        }
    }


    input.noBorder[type="checkbox"]+.single-view:before {
        display: none;
    }



    .pure-tree input[type="checkbox"]+.single-view .line-node-child {
        display: none;
    }


    .pure-tree input[type="checkbox"]:not(:checked)+.single-view~ul {
        display: none;
    }

    .pure-tree input[type="checkbox"]:checked~.single-view>.line-node-child {
        display: block;
    }

    .child-node {
        position: relative;
    }

    .pure-tree li {
        height: auto;
        display: table-row;
        width: 100%;
        white-space: nowrap;
        // @media screen and (max-width: 667px) {
        //   min-width: 200%;
        // }
    }

    .pure-tree li::before {
        content: "";
        position: absolute;
        top: -40px;
        left: -25px;
        width: 1px;
        height: 112%;
        background-color: $linecolor;
        display: none;

        @media screen and (max-width: 667px) {
            // top: -90px;
            // left: -25px;
            // height: 110%;

            top: -62px;
            left: -25px;
            height: 115%;
        }

        @media screen and (min-width: 668px) and (max-width:767px) {
            top: -32px;
        }


        @media screen and (min-width: 768px) and (max-width:991px) {
            // top: -68px;
            left: -25px;
            top: -39px;

        }

        @media screen and (min-width: 1000px) and (max-width: 1500px) {
            top: -40px;
            left: -25px;
        }
    }




    .pure-tree input[type="checkbox"]:checked+.single-view~ul>li::before {
        display: block;
        /* Show the border when the checkbox is checked */
    }

    .pure-tree input[type="checkbox"]:not(:checked)+.single-view~ul>li::before {
        display: none;
        /* Hide the border when the checkbox is not checked */
    }

    .containerHeight {
        @media screen and (max-width:667px) {
            height: 200px;
        }

        @media screen and (min-width:668px) and (max-width:991px) {
            height: 180px;
        }


    }

    li:after {
        content: "";
        position: absolute;
        width: 1px;
        bottom: 0px;
        // height: 10em;
        left: -25px;
        background-color: rgba(255,92,0,0.3);
    }

    .pure-tree .child-node.has-children:last-child:after {
        content: "";
        position: absolute;
        top: 33.5px;
        left: -25px;
        width: 1px;
        height: 100%;
        background-color: #ffdfca;
        // min-height: 20em;

        @media screen and (max-width: 667px) {
            top: 26.5px
        }

        @media screen and (min-width:668px) and (max-width: 767px) {
            top: 68px;

        }

        @media screen and (min-width: 768px) and (max-width:991px) {
            // top: 68px;
            top: 34px;
        }
    }

    .break-space {
        white-space: break-spaces;
    }
}

.investment-column {
    @media screen and (max-width: 499px) {
        min-width: 50vw;
    }

    @media screen and (min-width: 520px) and (max-width: 666px) {
        width: 30vw;
    }

    @media screen and (min-width: 667px) and (max-width: 991px) {
        width: 25vw;
    }

    @media screen and (min-width: 992px) {
        min-width: 15vw;
    }
}

.first-column {
    @media screen and (max-width: 499px) {
        min-width: 60vw;
    }

    @media screen and (min-width: 500px) and (max-width: 666px) {
        min-width: 40vw;
    }

    @media screen and (min-width: 667px) {
        // min-width: 40vw;
    }

    @media screen and (min-width: 991px) {
        min-width: 15vw;
    }
}

.second-column {}