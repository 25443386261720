.unreadDot{
    width: .5em;
    height: .5em;
    border-radius: 50%;
    background: var(--primary-color);
}

.readDot{
    width: .5em;
    height: .5em;
    border-radius: 50%;
    background: #828282;
}

.noti-container{
    background: #FFFFFF;
    box-shadow: 0px 1.25em 3.125em rgba(0, 0, 0, 0.07);
    border-radius: .5em;
}