.investment {
    .yellowRow {
        border-radius: 1.875em;
        border: 2px solid transparent;
        background: #FFF4DF;

        &:hover,
        &:focus {
            border: 2px solid var(--ff-5-c-00, #D5A529);
        }
    }

    .buy-btn {
        background: linear-gradient(141deg, #64FFA2 2.4%, #1CDA68 45.86%, #14B03F 92.13%);
        border-radius: 6.25em;
        transition: transform 500ms ease-out;
        position: relative;
        cursor: pointer;
        overflow: hidden;
        border: 1px solid #64FFA2;

        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: -100%;
            width: 100%;
            height: 100%;
            background: linear-gradient(90deg, transparent, #fff, transparent);
            transition: 0.5s;
            transform: rotate(-45deg);
        }

        &:hover,
        &:active,
        &:focus {
            background: linear-gradient(141deg, #64FFA2 5.4%, #1CDA68 50.86%, #14B03F 98.13%) !important;
            box-shadow: 0 0.5em 0.5em -0.4em #64FFA2;
            transform: translateY(-0.25em);

            &::before {
                left: 100%;
            }
        }
    }

    .sell-btn {
        background: linear-gradient(141deg, #FF6D62 2.4%, #DF000C 45.86%, #A70009 92.13%);
        border-radius: 6.25em;
        transition: transform 500ms ease-out;
        position: relative;
        cursor: pointer;
        overflow: hidden;
        border: 1px solid #FF6D62;

        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: -100%;
            width: 100%;
            height: 100%;
            background: linear-gradient(90deg, transparent, #fff, transparent);
            transition: 0.5s;
            transform: rotate(-45deg);
        }

        &:hover,
        &:active,
        &:focus {
            background: linear-gradient(141deg, #FF6D62 5.4%, #DF000C 50.86%, #A70009 98.13%) !important;
            box-shadow: 0 0.5em 0.5em -0.4em #FF6D62;
            transform: translateY(-0.25em);

            &::before {
                left: 100%;
            }
        }
    }
}

.total-text-box{
    width: 5em;
}

.tradeTable {

    .table th,
    .table td {
        padding: 0.5rem 0.625rem;
    }
    .table td {
        border: none !important;
    }
}