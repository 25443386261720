:root {
    --primary-color: #D5A529;
    --text-primary: #15389C;
    --text-title: #0A0A0A;
    --text-seconday: #777777;
    --text-error: #F44336;
    --menu-active-text-color: #ffffff;
    // --menu-active-text-color: #4EABEB;
    // --menu-inactive-text-color: #4EABEB;
    --menu-inactive-text-color: #686868;
    --menu-small-title: #939393;
    --page-title: #000000;
    --card-bg: #FCFCFC;
    --gray-800: #1D2939;
    --success: #039B00;
    --light-blue: #4EABEB;
    --text-placeholder: #686868;
    --middle-blue: #005CC6;
    --text-grey-1: #dfdfdf;
    --text-grey-2: #A7A7A7;
    --text-grey-3: #8d8d8d;
}

$textFieldBg: #FFFAF1;
$main_wrapper_bg: url('../images/background/bodyBg.png');
$primary_btn_bg: linear-gradient(141deg, #FFDB7E 2.4%, #E0B830 45.86%, #EBB019 92.13%);
$primary_btn_box_shadow: rgba(255, 219, 126, 0.5);
$group_label_input_bg: linear-gradient(167deg, #FFFAF1 6.86%, #FFFAF1 60.33%, #FFFAF1 91.7%);
$group_label_border_inactive: 2px solid #FFFAF1;
$group_label_border_active: 2px solid #D5A529;
$primary_btn_bg_hover: rgba(255, 219, 126, 0.8);
$card_custom_bg: #FFF;
$border-right-box-color: #FFECDE;
$odd_color: #FFF4DF;
$even_color: #FFFAF1;
$view_more_color: #D5A529;
$select_dropdown_arrow_container: #686868;
$card_qr_bg: #1D1500;
$input_text_color: #0A0A0A;

body {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    line-height: 1 !important;
    background-color: #FFFFFF !important;
    touch-action: pan-x pan-y;
}

.navigation-backdrop-control {
    z-index: -9;
}

[data-sidebar-style="overlay"] {
    .navigation-backdrop-control {
        z-index: 9;

        &.d-none {
            z-index: -1 !important;
        }
    }

    .bg-semi-transparent {
        background: rgba(0, 0, 0, 0.4);
    }
}

#main-wrapper{
    overflow: auto !important;
    background:$main_wrapper_bg;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top;
}

.fixedPosition {
    overflow-y: hidden;
}

.font-weight-300 {
    font-weight: 300;
}

.font-weight-400 {
    font-weight: 400;
}

.font-weight-500 {
    font-weight: 500;
}

.font-weight-600 {
    font-weight: 600;
}

.font-weight-700 {
    font-weight: 700;
}

.font-weight-800 {
    font-weight: 800;
}

.font-weight-900 {
    font-weight: 900;
}

.line-height-18 {
    line-height: 1.125em;
}

.line-height-20 {
    line-height: 1.25em;
}

.line-height-22 {
    line-height: 1.375em;
}

.line-height-24 {
    line-height: 1.5em;
}

.line-height-26 {
    line-height: 1.625em;
}

.line-height-30 {
    line-height: 1.875em;
}

.line-height-36 {
    line-height: 2.1875em;
}

.line-height-39 {
    line-height: 2.4375em;
}

.line-height-110percent {
    line-height: 110%;
}

.font-8 {
    font-size: 0.5em;
    @media screen and (max-width: 320px) {
        font-size: 1.75vw !important;
    }
}

.font-10 {
    font-size: 0.625em;
    @media screen and (max-width: 320px) {
        font-size: 2vw !important;
    }
}

.font-12 {
    font-size: 0.75em;
    @media screen and (max-width: 320px) {
        font-size: 2.25vw !important;
    }
}

.font-13 {
    font-size: 13px !important;
    @media screen and (max-width: 320px) {
        font-size: 2.375vw !important;
    }
}

.font-14 {
    font-size: 0.875em !important;
    @media screen and (max-width: 320px) {
        font-size: 2.5vw !important;
    }
}

.font-16 {
    font-size: 1em !important;
    @media screen and (max-width: 320px) {
        font-size: 2.75vw !important;
    }
}

.font-18 {
    font-size: 1.125em;
    @media screen and (max-width: 320px) {
        font-size: 3vw;
    }
}

.font-19 {
    font-size: 1.1875em;
    @media screen and (max-width: 320px) {
        font-size: 3.125vw;
    }
}

.font-20 {
    font-size: 1.25em;
    @media screen and (max-width: 320px) {
        font-size: 3.25vw;
    }
}

.font-22 {
    font-size: 1.375em;
    @media screen and (max-width: 320px) {
        font-size: 3.5vw;
    }
}

.font-24 {
    font-size: 1.5em;
    @media screen and (max-width: 320px) {
        font-size: 3.75vw;
    }
}

.font-25 {
    font-size: 1.5625em;
    @media screen and (max-width: 320px) {
        font-size: 3.875vw;
    }
}

.font-26 {
    font-size: 1.625em;
    @media screen and (max-width: 320px) {
        font-size: 4vw;
    }
}

.font-28 {
    font-size: 1.75em;
    @media screen and (max-width: 320px) {
        font-size: 4.25vw;
    }
}

.font-30 {
    font-size: 1.875em !important;
    @media screen and (max-width: 320px) {
        font-size: 4.5vw;
    }
}

.font-32 {
    font-size: 2em;
    @media screen and (max-width: 320px) {
        font-size: 4.75vw;
    }
}

.font-34 {
    font-size: 2.125em;
    @media screen and (max-width: 320px) {
        font-size: 5vw;
    }
}

.font-35 {
    font-size: 2.1875em;
    @media screen and (max-width: 320px) {
        font-size: 5.125vw;
    }
}

.font-40 {
    font-size: 2.5em;
    @media screen and (max-width: 320px) {
        font-size: 6vw;
    }
}

.font-46{
    font-size: 2.875em;
    @media screen and (max-width: 320px) {
        font-size: 6.5vw;
    }
}

.font-50 {
    font-size: 3em;
    @media screen and (max-width: 320px) {
        font-size: 7vw;
    }
}

.primary-text {
    color: var(--primary-color);
}

.text-light-blue {
    color: var(--light-blue);
}

.text-black-1 {
    color: var(--text-title);
}

.text-grey-1 {
    color: var(--text-seconday);
}

.text-grey-2 {
    color: #4C506B;
}

.text-grey-3 {
    color: #A9ACBB;
}

.text-grey-4 {
    color: #6D6D6D;
}

.text-grey-5 {
    color: var(--text-grey-1);
}

.text-grey-6 {
    color: var(--text-grey-2);
}

.text-grey-7{
    color: var(----text-grey-3);
}

.text-grey-8{
    color: #686868;
}


.text-blue {
    color: var(--primary-color-2);
}

.text-red {
    color: #D40000;
}

.text-green {
    color: var(--success);
}

.text-menu-inactive-text-color {
    color: var(--menu-inactive-text-color)
}

.letter-spacing-03p {
    letter-spacing: 0.3px;
}

.letter-spacing-05p {
    letter-spacing: 0.5px;
}

.letter-spacing--001em {
    letter-spacing: -0.01em;
}

// .input-group {
//     border-radius: 8px;
//     overflow: hidden;
//     background-color: #FCFCFC;
//     align-items: center;
//     border: 1px solid #F3F3F3;

//     &:hover,
//     &:focus,
//     &:active {
//         border: 1.5px solid #000000;
//     }

//     .icon {
//         width: 50%;
//     }
// }

.link-primary {
    color: #000 !important;

    &:hover,
    &:active {
        color: var(--primary-color) !important;
    }
}

.input-group-select {
    border: 1px solid #828282 !important;
}

.input-transparent {
    border: none !important;
    background-color: transparent !important;
    background: transparent !important;
    font-weight: 600 !important;
    font-size: 1.125em !important;
    color: $input_text_color !important;
}

.transparent-bg {
    background: transparent;
}

.errorTextRed {
    color: var(--text-error);
}

.requestCodeBtn {
    background: $primary_btn_bg;
    border: 1px solid var(--primary-color);
    border-radius: 6.25em;

    &:hover,
    &:active,
    &:focus {
        background-color: $primary_btn_bg_hover;
        border: 1px solid $primary_btn_bg_hover;
    }
}

.page-title-color {
    color: var(--page-title);
}

.opacity-07 {
    opacity: 0.7;
}

.min-height-350 {
    min-height: 350px;
}

.primary-btn,
.swal-button--confirm {
    background: $primary_btn_bg;
    border-radius: 100px;
    transition: transform 500ms ease-out;
    position: relative;
    cursor: pointer;
    overflow: hidden;
    border: 1px solid var(--primary-color);

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: -100%;
        width: 100%;
        height: 100%;
        background: linear-gradient(90deg, transparent, #fff, transparent);
        transition: 0.5s;
        transform: rotate(-45deg);
    }

    &:hover,
    &:active,
    &:focus {
        background: $primary_btn_bg !important;
        box-shadow: 0 0.5em 0.5em -0.4em $primary_btn_box_shadow;
        transform: translateY(-0.25em);

        &::before {
            left: 100%;
        }
    }
}

.cancel-btn, .swal-button--cancel{
    background: #686868 !important;
    border: 1px solid #ffffff;
    border-radius: 100px;
    transition: transform 500ms ease-out;
    position: relative;
    cursor: pointer;
    overflow: hidden;
    color: #fff;

    &:hover,
    &:active,
    &:focus {
        background: rgba(104, 104, 104, 0.4) !important;
        box-shadow: 0 0.5em 0.5em -0.4em rgba(104, 104, 104, 0.2);
        transform: translateY(-0.25em);

        &::before {
            left: 100%;
        }
    }
}

.swal-icon--warning__dot, .swal-icon--warning__body{
    background-color: var(--primary-color);
}
.swal-icon--warning{
    border-color:  var(--primary-color) !important;
}

.delete-btn {
    background: rgba(244, 67, 54, 1);
    border-radius: 100px;
    transition: transform 500ms ease-out;
    position: relative;
    cursor: pointer;
    overflow: hidden;
    border: 1px solid rgba(244, 67, 54, 1);
}


.btnGrey {
    cursor: pointer;
    background: rgba(130, 130, 130, 0.2);
    border-radius: 10px;
    transition: transform 500ms ease-out;
    border: none;

    &::after {
        border-radius: 10px;
        position: absolute;
        content: "";
        width: 0;
        height: 100%;
        top: 0;
        left: 0;
        z-index: -1;
        background-color: #DDDDDD;
        border-radius: 5px;
        transition: all 0.3s ease;
    }

    &:hover,
    &:active,
    &:focus {
        background: rgba(130, 130, 130, 0.8);
        transform: translateY(-0.25em);

        &::after {
            border-radius: 10px;
            right: 0;
            width: 100%;
        }
    }
}

// a {
//     &:hover,
//     &:active,
//     &:focus {
//         color: var(--primary-color) !important;
//         opacity: 0.8;
//     }
// }


.errorBorder {
    border: 1.5px solid var(--text-error) !important;
}

.card-custom-bg {
    background: $card_custom_bg;
    backdrop-filter: blur(10px);
    border-radius: 1.25em;
}

.card-qr-bg {
    background: $card_qr_bg;
    backdrop-filter: blur(10px);
    border-radius: 1.875em;

    .card-qr-bg-title {
        color: #ffffff;
    }

    .textFieldBg {
        background: #271C00;
        border-radius: 1.875em;
    }
}

.card-white {
    background: rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(10px);
    border-radius: 3.125em;
}

.card-grey-2 {
    background: var(--text-grey-2);
    backdrop-filter: blur(10px);
    border-radius: 3.125em;
}

.card-light-blue {
    background: rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(10px);
    border-radius: 3.125em;
}

.card-middle-blue {
    background: var(--middle-blue);
    backdrop-filter: blur(10px);
    border-radius: 3.125em;
}

.card-blue {
    background: #102E94;
    backdrop-filter: blur(10px);
    border-radius: 2em;

    .text-number-container {
        background: #1A237B;
        border-radius: 2em;
    }

    &:hover {
        background: #005CC6;

        .text-number-container {
            background: #004798;
            border-radius: 2em;
        }
    }
}

.card-lightBlue {
    background: var(--light-blue);
    // backdrop-filter: blur(10px);
    border-radius: 2em;
}

.text-gray-800 {
    color: var(--gray-800);
}

.custom-dropdown-container {
    .custom-dropdown {
        background: #ffffff;
        border-radius: 5px;
    }

    .custom-dropdown-menu {
        left: 50%;
        transform: translateX(-50%) !important;
        display: none;
        border-radius: 4px;
        margin-top: 8px;
        width: auto;
        padding: 10px;
        box-shadow: 0 0 5px -1px rgba(0, 0, 0, 0.3);
        background: #ECECEC;
        border: 1px solid #ECECEC;
        transform-origin: top left;
        z-index: 9999;
        position: absolute;
        top: 100%;
        overflow: scroll;

        @media screen and (max-width: 767px) {
            width: 100%;
        }
    }

    .custom-dropdown-menu span {
        padding: 10px;
        width: 100%;
        box-sizing: border-box;
        text-align: center;
        cursor: pointer;
        transition: background 0.3s ease;
    }

    .custom-menu-container {
        height: auto;
        overflow-y: scroll;
        flex-direction: column;
        display: flex;

        .lang-text {
            transition: all .5s ease-in;
            color: var(--gray-800);
        }

        @media screen and (max-width: 767px) {
            height: 50%;
        }
    }

    .custom-dropdown-menu span:hover {
        background: var(--primary-color-1);
        border-radius: 5px;
        color: #ffffff;
    }

    #openDropdown-lg:checked+.custom-dropdown-menu {
        display: flex;
        animation: openDropDown 0.4s ease 0s 1 forwards;
    }

    @keyframes openDropDown {
        from {
            transform: rotateX(50deg);
        }

        to {
            transform: rotateX(0deg);
        }
    }
}

.break-white-space {
    white-space: break-spaces;
}

.break-white-xs-space{
    @media screen and (max-width: 667px) {
        white-space: break-spaces !important;
    }
}

.row {
    &.display-flex1 {
        display: flex;
        flex-wrap: wrap;

        [class*='display-flex-col1'] {
            flex-grow: 1;
        }
    }
}

.status {
    &.success,
    &.completed {
        color: #359E1A;
    }

    &.pending {
        color: #686868;
    }

    &.failed {
        color: #C10808;
    }

    &.appealed {
        color: #FF147F;
    }

    &.pendingpayment {
        color: #686868;
    }

    &.pendingconfirm {
        color: #FF147F;
    }
}

.cancelPublishBtn {
    background: #ffffff;
    border: 1px solid #FF147F;
    border-radius: 5px;
    transition: transform 500ms ease-out;
    color: #FF147F;
    cursor: pointer;

    &::after {
        position: absolute;
        content: "";
        width: 0;
        height: 100%;
        top: 0;
        left: 0;
        z-index: -1;
        background-color: #FF147F;
        transition: all 0.3s ease;
    }

    &:hover,
    &:active,
    &:focus {
        transform: translateY(-0.25em);
        color: #ffffff;

        &::after {
            right: 0;
            width: 100%;
        }
    }
}

.cancelPublishBtnBlue {
    background: #ffffff;
    border: 1px solid var(--primary-color-2);
    border-radius: 5px;
    transition: transform 500ms ease-out;
    color: var(--primary-color-2);
    cursor: pointer;

    &::after {
        position: absolute;
        content: "";
        width: 0;
        height: 100%;
        top: 0;
        left: 0;
        z-index: -1;
        background-color: var(--primary-color-2);
        transition: all 0.3s ease;
    }

    &:hover,
    &:active,
    &:focus {
        transform: translateY(-0.25em);
        color: #ffffff;

        &::after {
            right: 0;
            width: 100%;
        }
    }
}


.image-container {
    border-radius: 10px;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    line-height: 1;
    background-position: 0 0, 0 0, 100% 0, 0 100%;
    background-size: 3px 100%, 100% 3px, 3px 100%, 100% 3px;
    background-repeat: no-repeat;
    background-image:
        repeating-linear-gradient(45deg, #828282, #828282 10px, transparent 10px, transparent 20px), // left
        repeating-linear-gradient(135deg, #828282, #828282 10px, transparent 10px, transparent 20px), // top
        repeating-linear-gradient(225deg, #828282, #828282 10px, transparent 10px, transparent 20px), // right
        repeating-linear-gradient(315deg, #828282, #828282 10px, transparent 10px, transparent 20px); // bottom;

    &.redBorder {
        background-image:
            repeating-linear-gradient(45deg, #C10808, #C10808 10px, transparent 10px, transparent 20px), // left
            repeating-linear-gradient(135deg, #C10808, #C10808 10px, transparent 10px, transparent 20px), // top
            repeating-linear-gradient(225deg, #C10808, #C10808 10px, transparent 10px, transparent 20px), // right
            repeating-linear-gradient(315deg, #C10808, #C10808 10px, transparent 10px, transparent 20px); // bottom;
    }
}

.greyBtn {
    cursor: pointer;
    background: #eeeeee;
    mix-blend-mode: normal;
    box-shadow: 0px 20px 50px rgba(0, 0, 0, 0.07);
    border-radius: 5px;
    color: var(--menu-inactive-text-color);

    &:hover {
        background: #bebebe;
        color: #ffffff;

        svg path {
            fill: #ffffff;
        }
    }

}

.documentContainer {
    margin: auto;
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.PDFDocument {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.PDFPage {
    box-shadow: 0 0 8px rgba(255, 20, 127, 0.2);
    ;
}

.PDFPageOne {
    margin-bottom: 25px;
}

.PDFPage>canvas {
    max-width: 100%;
    height: auto !important;
}


.controls-prev,
.controls-next {
    &.active {
        svg path {
            fill: #FF147F;
        }
    }
}

.requestCodeBtnBlue {
    background: #1D4EF2;
    border: 1px solid #1D4EF2;
    border-radius: 5px;

    &:hover,
    &:active,
    &:focus {
        background-color: rgba(29, 78, 242, 0.8);
        border: 1px solid rgba(29, 78, 242, 0.8);
        box-shadow: 0 0.5em 0.5em -0.3em rgba(29, 78, 242, 0.4);
    }
}

.pagination {
    justify-content: center;
    align-items: center;
}

.pagination a {
    // padding: 5px 10px;
    padding: 2.5px 7.5px;
    border-right: none;
    border-left: none;
    // background: var(--primary-color);
    border-radius: 6px;
    margin: 0px 3px;
}

.pagination__previouslink, .pagination__nextlink {
    border-radius: 50% !important;
    color: #ffffff !important;
    background: var(--primary-color);
}

.pagination__link--active {
    border-radius: 4px;
    // padding: 10px 5px;

    a {
        background: var(--primary-color) !important;
        color: #fff !important;
        padding: 5px 10px;
    }
}

.previous,
.next {
    width: 3rem;
    text-align: center;
}

.grayscale {
    filter: grayscale(100%);
}

.smallAvatarContainer {
    background-color: #f2f2f2;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
    border-radius: 50%;
    width: 30px;
    aspect-ratio: 1;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;

    &.empty {
        background-color: #1D4EF2;
        box-shadow: 0px 0px 5px rgba(29, 78, 242, 0.2);
    }
}

.smallAvatarUrl {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    object-fit: cover;
    object-position: center;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

.PhoneInputInput {
    border: none !important;
    background-color: transparent !important;
    background: transparent !important;
    font-weight: 400;
    font-size: 0.875em;
    color: var(--text-primary);
    padding: 0.3125rem 1.25rem;
    height: 3.5rem;

    @media only screen and (max-width: 87.5rem) {
        height: 2.75rem;
    }
}

.countryImg {
    width: 2.5em;
    aspect-ratio: 1;
    border-radius: 50%;
}

.smallCountryImg {
    width: 1.5em;
    aspect-ratio: 1.5;
}

.starImg {
    width: 2em;
    filter: grayscale(1);

    &.active {
        animation: svg-shadow 1.5s ease-in-out infinite alternate;
    }
}

@keyframes svg-shadow {
    from {
        filter: drop-shadow(0 0 3px #fff) drop-shadow(0 0 3px rgba(255, 20, 127, 0.3)) drop-shadow(0 0 3px rgba(255, 20, 127, 0.5));
    }

    to {

        filter: drop-shadow(0 0 5px #fff) drop-shadow(0 0 5px rgba(255, 20, 127, 0.3)) drop-shadow(0 0 5px rgba(255, 20, 127, 0.5));
    }
}

@keyframes glowing {
    0% {
        box-shadow: 0 0 -28px 3px #99B900;
    }

    40% {
        box-shadow: 0 0 15px 3px #99B900;
    }

    60% {
        box-shadow: 0 0 15px 3px #99B900;
    }

    100% {
        box-shadow: 0 0 -28px 3px #99B900;
    }
}

.video-container {
    background-color: #000000;
    display: flex;
    justify-content: center;
}

.video {
    width: auto;
    height: 80vh;
    object-fit: cover;
}

.video-bottom {
    background-color: #ffffff;
}

.groupLabel-select{
    border-radius: 6.25em;
    border: $group_label_border_inactive;
    background: $group_label_input_bg;
    
    &:hover,
    &:focus,
    &:active {
        background: #ffffff;
        border: $group_label_border_active;
    }
}

.groupLabel-input {
    border-radius: 6.25em;
    border: $group_label_border_inactive;
    background: $group_label_input_bg;

    &:hover,
    &:focus,
    &:active {
        background: #ffffff;
        border: $group_label_border_active;
    }

    .icon {
        width: 100%;
        padding-right: 8px;
    }
    &.overflow{
      overflow: initial;
    }
}

.groupDisabled-input {
    border-radius: 100px;
    overflow: hidden;
    background-color: #e0e0e0;
    align-items: center;
    border: 1px solid #e0e0e0;

    .icon {
        width: 100%;
        padding-right: 8px;
    }
}

.input-group {
    border-radius: 8px;
    overflow: hidden;
    background-color: transparent;
    align-items: center;
    border: 1px solid transparent;

    &:hover,
    &:focus,
    &:active {
        border: 1.5px solid transparent;
    }

    .icon {
        width: 100%;
        padding-right: 8px;
    }
}

.form-control {
    padding-left: 0px !important;
    padding-right: 0px !important;
    height: 2.2em !important;
    font-weight: 600 !important;
    font-size: 1.125em !important;
    color: var(--text-title) !important;
}

input::placeholder {
    color: var(--text-placeholder) !important;
    text-transform: capitalize !important;
}

.border-white {
    border-left: 2px solid #FFFFFF;
    border-right: 2px solid #FFFFFF;
}

.border-right-white {
    border-right: 2px solid #FFFFFF;
    border-bottom: none;

    @media only screen and (max-width: 667px) {
        border-right: none;
        border-bottom: 2px solid #FFFFFF;
    }
}

.border-right-box {
    border-right: 2px solid $border-right-box-color;
    border-bottom: none;

    @media only screen and (max-width: 667px) {
        border-right: none;
        border-bottom: 2px solid $border-right-box-color;
    }
}

.overflow-wrap-anywhere {
    overflow-wrap: anywhere;
}

.boxIconSize {
    width: 70px;

    @media screen and (max-width: 667px) {
        width: 70px;
    }

    @media screen and (max-width: 300px) {
        width: 50px;
    }
}

.noDataImgSize {
    width: 20%;
}

.webkit-app-auto {
    -webkit-appearance: auto !important;
}

.logoSize {
    width: 40%;

    @media screen and (max-width: 992px) {
        width: 60%;
    }

    @media screen and (max-width: 677px) {
        width: 60%;
    }
}

.mobileLogo {
    width: 75%;
    display: none;

    img {
        width: 100%;
    }

    @media screen and (min-width: 481px) and (max-width: 766px) {
        width: 50%;
    }

    @media screen and (max-width: 767px) {
        display: block;
    }
}

.header-icon2 {
    width: 18px;
    height: 18px;
}

.resumeTradingPanel {
    background-color: $primary_btn_bg;
    width: 100%;
    z-index: 2;

    span {
        color: #fff;
        line-height: 18px;
    }

    .mailToLink {
        color: #fff;
        text-decoration: underline;

        &:hover,
        &:focus,
        &:active {
            color: #fff;
            text-decoration: underline;
        }
    }
}

.css-1wa3eu0-placeholder{
    color: var(--text-placeholder) !important;
}

.statusBox{
    border-radius: 10px;
    &.PENDING{
       border: 2px solid darkgrey;
    }

    &.REJECTED{
        border: 2px solid red;
       
    }
    &.APPROVED{
        border: 2px solid green;
    }
    &.COMPLETED{
        border: 2px solid green;
    }
}

.status{
    &.PENDING{
        color:#686868;
    }

    &.REJECTED{
        color:red;
    }
    &.APPROVED{
        color: green;
    }
    &.COMPLETED{
        color: green;
    }
}

.orangeContainer{
    border-radius: 0.75em;
    background: var(--Linear, linear-gradient(167deg, #FFF6F0 6.86%, #FFF7F2 60.33%, #FFECDE 91.7%));
}

.whiteContainer {
    background: #FFFFFF;
    border: 2px solid #FFFFFF;
    border-radius: 1.875em;

    .white-container-title {
        color: #000000;
    }

    .textFieldBg {
        background: $textFieldBg;
        border-radius: 1.875em;
    }
    .blackFieldBg{
        background: #1D1500;
        border-radius: 1.875em;
    }
}

.yellowContainer{
    background: linear-gradient(141deg, #FFDB7E 2.4%, #E0B830 45.86%, #EBB019 92.13%);
    // border: 2px solid #FFDB7E;
    border-radius: 1.875em;

    .white-container-title {
        color: #ffffff;
    }

    .textFieldBg {
        background: #D5A529;
        border-radius: 1.875em;
    }
}

.table-custom{
    border-collapse: separate;
    border-spacing: 0 5px;
    th, td{
        border: 0px !important;
    }
    tbody tr{
        margin-bottom: .5em;
    }
    td:first-child { 
        border-top-left-radius: 1.875em; 
        border-bottom-left-radius: 1.875em; 
    }
    td:last-child { 
        border-top-right-radius: 1.875em; 
        border-bottom-right-radius: 1.875em; 
    }
    .rewardIcon{
        width: 75%;
        @media screen and (min-width: 992px) {
            max-width: 2em;
        }
    }
    .firstPlaceBg{
        border-radius: 1.875em;
        background: #1D7E84;
    }
    .secondPlaceBg{
        border-radius: 1.875em;
        background: rgba(29,126,132, 0.6);
    }
    .thirdPlaceBg{
        border-radius: 1.875em;
        background: rgba(29,126,132, 0.4);
    }
    .totalBg{
        border-radius: 1.875em;
        background: #10616F;
        
    }
}

.table-list{
    white-space: nowrap;
    border-collapse: separate;
    border-spacing: 0px 10px;
    th, td{
        border: 0px !important;
    }
    td{
        color: #000000;
        // padding: 1px !important;  
        @media screen and (max-width: 520px) {
            min-width: 150px;
            &.description{
                min-width: 250px;
            }
        }
       
    }
    td:first-child { 
        border-top-left-radius: 1.875em; 
        border-bottom-left-radius: 1.875em; 
    }
    td:last-child { 
        border-top-right-radius: 1.875em; 
        border-bottom-right-radius: 1.875em; 
    }
    .odd {
        background: $odd_color;
    }
    .even {
        background: $even_color;
    }
}

::-webkit-scrollbar {
    width: 5px;
    height: 10px;
    border-radius: 3.125em;
}

/* Track */
::-webkit-scrollbar-track {
    background: #D5E2E9;
    border-radius: 3.125em;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: var(--primary-color) !important;
    border-radius: 3.125em;
}

/* Handle on hover */
::-webkit-scrollbar-thumb {
    background: var(--primary-color) !important;
}

.left-arrow{
    position: absolute;
    left: 0%;
    top: 50%;
    z-index: 1;
    transform: translateY(-50%);
}
.right-arrow{
    position: absolute;
    right: 0%;
    top: 50%;
    z-index: 1;
    transform: translateY(-50%) rotate(180deg);
}

.modal-backdrop.show {
    opacity: 1 !important;
}

.modal-backdrop{
    background: rgba(39, 39, 39, 0.78) !important;
    backdrop-filter: blur(15px);
}

.modalColorBg{
    .modal-content{
        background: #ffffff;
        backdrop-filter: blur(10px);
        border-radius: 1.875em;
    }
    .modalBody{
        max-height: 80vh;
        overflow-y: auto;
    }
    @media screen and (max-width: 767px) {
        .modalBody{
            max-height: 60vh;
            overflow-y: auto;
        }
    }
}

.MuiPickersToolbarText-toolbarTxt{
    color: #ffffff !important;  
}

.MuiPickersToolbar-toolbar, .MuiPickersDay-daySelected{
    background-color: var(--primary-color) !important;
}

.MuiButton-textPrimary{
    color: var(--primary-color) !important;
}


.form-control {
    &.date{
        .MuiInputBase-root{
            input::placeholder{
                font-weight: 600 !important;
                // font-size: 1em !important;
                color: var(--text-placeholder) !important;
                opacity: 1;
                text-transform: capitalize;
            }
            
            input:read-only::placeholder {
                color: var(--text-placeholder) !important;
                font-weight: 600 !important;
                // font-size: 1em !important;
                opacity: 1;
            }
        }
    }
}

.MuiInput-formControl .MuiInput-input::placeholder, .MuiInput-formControl .MuiInputBase-input::placeholder {
    color: var(--text-placeholder) !important;
    font-weight: 600 !important;
    font-size: 1em !important;
}

.MuiInputBase-input{
    color: #000000 !important;
    font-weight: 600 !important;
    font-size: 1.125em !important;
}

input:read-only::placeholder {
    color: var(--text-placeholder) !important;
    // font-weight: 600 !important;
    // font-size: 1.125em !important;
}

input:read-only {
    font-weight: 600 !important;
    font-size: 1.125em !important;
}

.MuiInput-underline:before, .MuiInput-underline:after, .MuiInput-underline.Mui-error:after{
    border-color: transparent !important;
}

.form-check-input{
    border-radius: 50% !important;
}

.form-check-input:checked{
    background-color: var(--primary-color) !important;
    border-color: var(--primary-color) !important;
}

[class^="css-"][class$="-control"], [class^="css-"][class$="-container"]{
    min-height: 2.2em !important;
    height: 2.2em !important;
}
[class^="css-"][class$="-Svg"] {
    fill: #ffffff;
    background: $select_dropdown_arrow_container;
    border-radius: 50%;
}
[class^=" css-"][class$="-indicatorContainer"] {
    padding: 0px !important;
}

[class^=" css-"][class$="-indicatorSeparator"] {
    width: 0px !important;
}

.transfer-wallet-option{
    @media screen and (max-width: 540px) {
        [class^="css-"][class$="-ValueContainer"] {
            justify-content: center;
        }
    }
    
}

.redBorder{
    border-radius: 1.875em;
    border: 1px solid red;
}

.login-link{
    &:hover{
        color: var(--primary-color);
        opacity: 0.6;
    }
}

@media only screen and (max-width: 74.9375rem){
    [data-header-position="fixed"] .content-body {
        padding-top: 5.5rem !important;
    }
}

.overflow-x-auto{
    overflow-x: auto;
}

.bgImgModal{
    .modal-content{
        background-repeat: no-repeat;
        background-size: cover !important;
        border-radius: 3.125em;
    }
    .modalBody{
        max-height: 80vh;
        overflow-y: auto;
    }
    @media screen and (max-width: 767px) {
        .modalBody{
            height: 70vh;
            overflow-y: auto;
        }
    }
}

.view-more-text{
    color: $view_more_color;
    &:hover,
    &:active {
        color: var(--primary-color) !important;
    }
}

.deleteBtn {
    background: rgba(244, 67, 54, 1);
    border: 1px solid rgba(244, 67, 54, 1);
    border-radius: 3.125em;
    color: #ffffff;
    &:hover,
    &:active,
    &:focus {
        background-color: rgba(244, 67, 54, 0.8);
        border: 1px solid rgba(244, 67, 54, 0.8);
        box-shadow: 0 0.5em 0.5em -0.3em rgba(244, 67, 54, 0.4);
    }
}

.disabledDeleteBtn {
    background: rgba(0, 0, 0, 0.4);
    border: 1px solid rgba(0, 0, 0, 0.4);
    border-radius: 3.125em;
    color: rgba(255,255,255,0.8);
    &:hover,
    &:active,
    &:focus {
        background-color: rgba(0, 0, 0, 0.4);
        border: 1px solid rgba(0, 0, 0, 0.4);
        box-shadow: 0 0.5em 0.5em -0.3em rgba(0, 0, 0, 0.3);
    }
}

.tableContainerAccordion {
    .odd{
        background: $odd_color;
    }
    .even{
        background: $even_color;
    }
    .border-top{
        border-top-color: #686868 !important;
        border-top-width: 1px;
    }
    .accordion-item {
        // background: #FFF9F4;
        border-radius: 1.25em;

        .accordion__button {
            padding-bottom: 2px !important;
        }
    }

    .accordion-item-inactive {
        // background: #FFF1E7;
        border-radius: 1.25em;
    }

    // .accordion__button {
    //     background-color: transparent !important;
    // }

    // .accordionTitle {
    //     font-style: normal;
    //     font-weight: 700;
    //     font-size: 1em;
    //     line-height: 29px;
    //     color: #0093FF;

    //     @media (max-width: 667px) {
    //         font-weight: 500;
    //         font-size: 1em;
    //     }

    //     @media (max-width: 300px) {
    //         font-weight: 500;
    //         font-size: 15px;
    //         // line-height: 25px;
    //     }
    // }

    // .accordionSideItemText {
    //     font-style: normal;
    //     font-weight: 400;
    //     font-size: 16px;
    //     line-height: 29px;
    //     color: #0093FF;
    //     width: fit-content;

    //     @media (max-width: 667px) {
    //         font-weight: 400;
    //         font-size: 12px;
    //     }
    // }

    // .accordionTitle-inactive {
    //     font-style: normal;
    //     font-weight: 700;
    //     font-size: 16px;
    //     line-height: 29px;
    //     color: #000;

    //     @media (max-width: 667px) {
    //         font-weight: 500;
    //         font-size: 16px;
    //     }

    //     @media (max-width: 300px) {
    //         font-weight: 500;
    //         font-size: 15px;
    //         line-height: 25px;
    //     }
    // }

    // .accordionSideItemText-inactive {
    //     font-style: normal;
    //     font-weight: 400;
    //     font-size: 16px;
    //     line-height: 29px;
    //     color: #000;

    //     @media (max-width: 667px) {
    //         font-weight: 400;
    //         font-size: 12px;
    //     }
    // }


    // .accordionContent {
    //     color: #000;

    //     @media (max-width: 992px) {
    //         font-size: 14px;
    //         line-height: 25px;
    //     }

    //     @media (max-width: 677px) {
    //         font-size: 12px;
    //         line-height: 22px;
    //     }
    // }


    // .accordion__button {
    //     background: transparent
    // }

    // .accordion__panel {
    //     padding-left: 8px;
    //     padding-top: 4px !important;
    // }


    .accordion__button:before {
        display: none;
    }

    .lh-1 {
        line-height: 1;
    }

    .accordion__button::before {
        display: none !important;
    }

    .accordionIcon {
        color: #fff;
        font-size: 16px;
    }

    .accordion-inactive {
        background: #777777;
        border-radius: 50%;
        width: 2em;
        height: 2em;
        text-align: center;
    }

    .accordion-active {
        background: #4EABEB;
        border-radius: 50%;
        width: 2em;
        height: 2em;
        text-align: center;
    }

    .accordionLine {
        width: 100%;
        height: 1px;
        color: #123455;
        background-color: #123455;
        border: 1px solid #123455;
    }
}

.border-bottom-grey {
    border-bottom: 1px solid #CCCCCC;
}

.cancelItemBtn {
    background: #ffffff;
    border: 1px solid #ff0000;
    border-radius: 5px;
    transition: transform 500ms ease-out;
    color: #ff0000;
    cursor: pointer;

    &::after {
        position: absolute;
        content: "";
        width: 0;
        height: 100%;
        top: 0;
        left: 0;
        z-index: -1;
        background-color: #ff0000;
        transition: all 0.3s ease;
    }

    &:hover,
    &:active,
    &:focus {
        transform: translateY(-0.25em);
        -webkit-transform: translateY(-0.25em);
        color: #ffffff;

        &::after {
            right: 0;
            width: 100%;
        }
    }
}


.input-transparent-select{
    border: none !important;
    background-color: transparent !important;
    background: transparent !important;
    font-weight: 500 !important;
    color: var(--text-seconday) !important;
    font-size: 13px !important;
    line-height: 1 !important;
    height: 13px !important;
    padding: 0px !important;
    @media screen and (max-width: 320px) {
        font-size: 2.375vw !important;
    }
}